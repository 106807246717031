<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
    <!-- EOC -->
    <!-- BOC:[header] -->
    <v-row class="mb-3">
      <v-col><h1>Sponsored Students</h1></v-col>
      <v-col class="text-right">
        <v-btn
          color="primary"
          large
          :to="{ name: 'PageModeratorSponsoredStudentAdd' }"
        >
          + New Student
        </v-btn>
      </v-col>
    </v-row>
    <!-- EOC -->
    <!-- BOC:[tabs] -->
    <v-tabs
      v-model="tab"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-tab v-for="item in tabs" :key="item.key" :href="`#${item.key}`">
        {{ item.label }}
      </v-tab>
    </v-tabs>
    <!-- EOC -->
    <v-tabs-items v-model="tab">
      <!-- BOC:[model] -->
      <v-tab-item key="all" value="all" class="px-1 py-2">
        <!-- BOC:[table] -->
        <!-- BOC:[table] -->
        <BreadBrowseTable
          role="Moderator"
          :model="model"
          :url="`${$api.servers.sso}/api/v1/en/moderator/sponsoredStudent`"
          :isSearchable="true"
        ></BreadBrowseTable>
        <!-- EOC -->
      </v-tab-item>
      <!-- EOC -->
      <!-- BOC:[model] -->
      <v-tab-item key="pending" value="pending" class="px-1 py-2">
        <!-- BOC:[table] -->
        <BreadBrowseTable
          role="Moderator"
          :model="model"
          :url="`${$api.servers.sso}/api/v1/en/moderator/sponsoredStudent/pending`"
          :isSearchable="true"
        ></BreadBrowseTable>
        <!-- EOC -->
      </v-tab-item>
      <v-tab-item key="query" value="query" class="px-1 py-2">
        <!-- BOC:[table] -->
        <BreadBrowseTable
          role="Moderator"
          :model="model"
          :url="`${$api.servers.sso}/api/v1/en/moderator/sponsoredStudent/query`"
          :isSearchable="true"
        ></BreadBrowseTable>
        <!-- EOC -->
      </v-tab-item>
      <v-tab-item key="approved" value="approved" class="px-1 py-2">
        <!-- BOC:[table] -->
        <BreadBrowseTable
          role="Moderator"
          :model="model"
          :url="`${$api.servers.sso}/api/v1/en/moderator/sponsoredStudent/approved`"
          :isSearchable="true"
        ></BreadBrowseTable>
        <!-- EOC -->
      </v-tab-item>
      <v-tab-item key="rejected" value="rejected" class="px-1 py-2">
        <!-- BOC:[table] -->
        <BreadBrowseTable
          role="Moderator"
          :model="model"
          :url="`${$api.servers.sso}/api/v1/en/moderator/sponsoredStudent/rejected`"
          :isSearchable="true"
        ></BreadBrowseTable>
        <!-- EOC -->
      </v-tab-item>
      <v-tab-item key="terminated" value="terminated" class="px-1 py-2">
        <!-- BOC:[table] -->
        <BreadBrowseTable
          role="Moderator"
          :model="model"
          :url="`${$api.servers.sso}/api/v1/en/moderator/sponsoredStudent/terminated`"
          :isSearchable="true"
        ></BreadBrowseTable>
        <!-- EOC -->
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
//BOC:[model]
import model from "@/models/items/sponsoredStudent";
//EOC
//BOC:[table]
import BreadBrowseTable from "@/components/Bread/BreadBrowseTableV2";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //BOC:[table]
    BreadBrowseTable,
    //EOC
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[model]
    model: model,
    //EOC
    //BOC:[role]
    role: "Moderator",
    //EOC
    //BOC:[tabs]
    tab: "pending",
    tabs: [
      {
        key: "all",
        label: "All",
      },
      {
        key: "pending",
        label: "Pending",
      },
      {
        key: "query",
        label: "In Query",
      },
      {
        key: "approved",
        label: "Approved",
      },
      {
        key: "rejected",
        label: "Rejected",
      },
      {
        key: "terminated",
        label: "Terminated",
      },
    ],
    //EOC
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("model.name.dashboard"),
      to: { name: "Page" + this.role + "Dashboard" },
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.$t("model.name." + this.model.name.plural.toLowerCase()),
      to: this.$_getRouteBrowse(this.role, this.model.key),
      exact: true,
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>